import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import SidebarLinks from "../../components/SidebarLinks"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function RiversTJenkins() {
  return (
    <Layout>
      <GatsbySeo
        title="Rivers T. Jenkins | Tecklenburg, Jenkins & Jacoby, LLC | Charleston, SC"
        description="Rivers T. Jenkins, III is a native of Charleston and a partner at
            Tecklenburg, Jenkins & Jacoby. After receiving his law degree, Mr. Jenkins
            began practicing in Charleston as a general civil litigator, with an
            emphasis on admiralty and maritime law."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/attorneys/rivers-t-jenkins/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <SidebarLinks />
        </section>
        <section className={styles.content}>
          <h1>Rivers T. Jenkins, III</h1>
          <StaticImage
            src="../../images/attorneys/rivers-t-jenkins.png"
            className={styles.attorneyImage}
            width={200}
            alt="Rivers T. Jenkins III"
          />
          <p>
            Rivers T. Jenkins, III is a native of Charleston and a partner at
            Tecklenburg, Jenkins & Jacoby. After receiving his law degree, Mr.
            Jenkins began practicing in Charleston as a general civil litigator,
            with an emphasis on admiralty and maritime law. He is admitted to
            practice in the State of South Carolina, United States District
            Court for South Carolina, and the Fourth Circuit Court of Appeals.
            Mr. Jenkins’ litigation experience includes cargo/transportation,
            personal injury defense, vessel arrests and foreclosure, marine
            insurance, products liability, warranty claims, and complex
            commercial{" "}
            <Link to="/practice-areas/south-carolina-litigation">
              litigation
            </Link>
            .
          </p>
          <h2>Bar Admissions:</h2>
          <ul>
            <li>
              <h3>Fourth Circuit Court of Appeals</h3>
            </li>
            <li>
              <h3>United States District Court for South Carolina</h3>
            </li>
            <li>
              <h3>State of South Carolina</h3>
            </li>
          </ul>
          <h2>Education:</h2>
          <ul>
            <li>
              <h3>University of Georgia- Bachelor of Arts Economics</h3>
            </li>
            <li>
              <h3>University of South Carolina – Juris Doctor</h3>
            </li>
          </ul>
          <h2>Professional Associations and Memberships:</h2>
          <ul>
            <li>
              <h3>
                <a href="https://www.charlestoncountybar.org/">
                  Charleston County Bar Association
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.scbar.org/">
                  South Carolina Bar Association
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://propellerclub.us/">
                  The Propeller Club of the United States
                </a>{" "}
                – Member
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.seali.com/">
                  The Southeastern Admiralty Law Institute
                </a>{" "}
                – Member, former Charleston Port Director
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://mlaus.org/">
                  Maritime Law Association of the United States
                </a>{" "}
                – Member
              </h3>
            </li>
          </ul>
          <h2>Publications and Speaking Engagements:</h2>
          <ul>
            <li>
              <h3>“Shipping Law”, 22 S.C. Juris – Co-Author</h3>
            </li>
            <li>
              <h3>
                Charleston County Bar Association CLE program – Lecturer on
                admiralty law
              </h3>
            </li>
          </ul>
          <p className={styles.attorneyFooter}>
            <Link to="/contact">Contact</Link>
            &nbsp;&nbsp;&nbsp;{" "}
            <a href="/Rivers-T-Jenkins.vcf" download="Rivers-T-Jenkins.vcf">
              Download VCard
            </a>
            &nbsp;&nbsp;&nbsp;{" "}
            <a href="https://www.linkedin.com/in/rivers-jenkins-3bb3a017/">
              View LinkedIn Profile
            </a>
          </p>
        </section>
      </section>
    </Layout>
  )
}
